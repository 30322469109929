import React, { useState } from "react";
import MenuClassique from "../header/desktop/menuClassique";
import MenuClassiqueDeux from "../header/desktop/menuClassiqueDeux";
import MenuImageGrande from "../header/desktop/menuImageGrande";
import MenuImagePetite from "../header/desktop/menuImagePetite";

import MenuClassiqueMobile from "../header/mobile/menuClassique";
import MenuImageMobile from "../header/mobile/menuImage";

const Menu = () => {
  return (
    <>
      <div className="desktop">
        <MenuImageGrande />
      </div>
      <div className="mobile">
        <MenuImageMobile />
      </div>
    </>
  );
};

export default Menu;
