import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import "./index.css?vers=1.0.19";
import DataImgBackground from "./data";

const ImageBackground = ({ data }) => {
  return (
    <>
      <div className="grid3_2_1">
        {data.map((item, key) => (
          <DataImgBackground item={item} key={key} />
        ))}
      </div>
    </>
  );
};

ImageBackground.propTypes = {
  data: PropTypes.any,
};

ImageBackground.defaultProps = {
  data: "",
};
export default ImageBackground;
