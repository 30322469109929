import React from "react";
import BcImage from "../img/imgBcImageCompetances.jpg";
import Title from "../../../components/title";

const listCompetances = [
  "React",
  "Typescript",
  "Node js",
  "Vue.js",
  "BDD / SQL / MongoDb",
  "Next",
  "Nuxt.js",
  "Html / CSS",
  "Javascript / Ajax",
  "Angular",
  "Python",
  "Git / Github / Gitlab",
];

const CompetencesDesktop = () => {
  return (
    <>
      <div
        className="styleImageBackgroundNoFull relative flex alignItemCenter justifyContentCenter width100"
        style={{ background: "url(" + BcImage + ")" }}
      >
        <div className="flex justifyContentCenter alignItemCenter">
          <div className="width100">
            <div className="filtreImage"></div>
            <div className="relative">
              <Title title="Compétences" />
              <div className="grid4_2_2 white">
                {listCompetances.map((item) => {
                  return (
                    <div className="flex alignItemCenter gap10 alignItemCenter gap20">
                      <div className="elipse"></div>
                      <div className="h4 uppercase">{item}</div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CompetencesDesktop;
