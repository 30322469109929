import React from "react";
import Grid from "../../components/grid";
import Title from "../../components/title";
import Btn from "../../components/btn";
import "./index.css?vers=1.0.19";
import FormationImg from "./img/formation.png";
import DataFormation from "./data/dataFormation";
import DataHobbies from "./data/dataHobbies";
import DataExperiences from "./data/dataExperiences";
import CompetencesDesktop from "./data/competencesDesktop";
// import CompetencesMobile from "./data/competencesMobile";
import Contact from "../../components/contact";
import Menu from "../../components/menu";
import ImageBackground from "../../components/imageBackground";
import DataProjets from "./data/dataProjets";

const Home = () => {
  return (
    <>
      <Menu />
      <section>
        <Title title="Introduction" />
        <div className="width80 auto center">
          <div className="h3">
            Passionné d'informatique et en particulier par la programmation
            (autant front que back) depuis maintenant quelques années, je vous
            présente dans ce portfolio mon parcours ainsi que les projets les
            plus pertinants que j'ai réalisé. La majorité sont des projets
            personnels.
          </div>
        </div>
      </section>

      <div className="bc-beige" id="projets">
        <section>
          <Title title="Mes projets" colorTitle="white" />
          <ImageBackground data={DataProjets} />
        </section>
      </div>

      <section id="formations">
        <Title title="Formations" />
        <div className="flex alignItemCenter justifyContentCenter columnMReverse">
          <div className="width50 width100M formationImgdddd">
            <img src={FormationImg} alt="Adrien Meynard" />
          </div>
          <DataFormation />
        </div>
      </section>

      <div className="bc-beige" id="experiences">
        <section>
          <Title title="Experiences Professionnelles" colorDesc="black " />

          <Grid
            bc=" bc_gradient "
            grid="grid3_2_1"
            color="white "
            data={DataExperiences}
          />
        </section>
      </div>

      <section id="stages">
        <Title title="Stages" />
        <br />
        <div className="flex column">
          <div className="width50 auto width100M">
            <div className="flex alignItemCenter gap20">
              <div>
                <div className="bc_etoile_stage flex alignItemCenter justifyContentCenter borderRadius50">
                  <i className="fas fa-star yellow"></i>
                </div>
              </div>
              <div className="column">
                <div className="h3 black">
                  WebMaster - Mairie de Sète , Sète
                </div>
                <div className="h4 orangered">mai - juin 2020 (2 mois)</div>
              </div>
            </div>
            <br />
            <br />
            <div className="flex alignItemCenter gap20">
              <div>
                <div className="bc_etoile_stage flex alignItemCenter justifyContentCenter borderRadius50">
                  <i className="fas fa-star yellow"></i>
                </div>
              </div>
              <div className="column">
                <div className="h3 black">
                  WebMaster - Domaine de Selhac, Frontignan
                </div>
                <div className="h4 orangered">mai - juin 2019 (2 mois)</div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="bc-beige" id="hobbies">
        <section>
          <Title title="Hobbies" colorTitle="white" />
          <br />
          <DataHobbies />
        </section>
      </div>
      <div id="competences">
        <CompetencesDesktop />
      </div>
      <section id="contact">
        <Title title="Contactez-moi" />
        <div>
          <Contact />
        </div>
      </section>
    </>
  );
};

export default Home;
