import Logo from "../dataImg/logo.png";
import Btn from "../../btn";
import ImageBackground from "../dataImg/grandeImageFondEcran.jpg";

const DataContent = {
  nomEntreprise: "Adrien Meynard",
  title: "Developpeur Full Stack",
  desc: "Ingénieur informatique",
  texte: "34 000 MTP",
  text1: "06 95 63 25 15",
  text2: "Né le 16 septembre 1999",
  text1: "contac@adrien-meynard.fr",
  text2: <Btn name="CV format PDF" destination="/cv?v1" center={false} />,

  btnName: "CV format PDF",
  // btnDestination: "./cv.pdf",
  logo: Logo,
  logoMobile: Logo,
  color: "white ",
  bc: "black ", // Background Menu
  bcImage: ImageBackground,
  bcColorMenuOuvert: "bleu ", //MOBILE background color du menu ouvert
  fixed: false,
  mailMenuOuvert: "contact@adrien-meynard.fr",
  telMenuOuvert: "+33 6 95 63 25 15",
};

export default DataContent;
