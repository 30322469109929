import React from "react";
import DataNavigation from "../../dataNavigation";
import DataContent from "../../dataContent";
import PropTypes from "prop-types";
import Btn from "../../../btn";
// import { BrowserRouter as Router, Link } from "react-router-dom";
import "./index.css?vers=1.0.19";
import PhpLogo from "./img/phpLogo.png";
import ReactLogo from "./img/reactLogo.png";
import Logo1 from "./img/db.jpg";
import Logo2 from "./img/db1.png";
import Logo3 from "./img/db2.png";
import NodeLogo from "./img/nodeLogo.png";
import { HashLink as Link } from "react-router-hash-link";

const MenuImageGrande = ({
  color,
  title,
  logo,
  bcImage,
  desc,
  btnName,
  btnDestination,
}) => {
  return (
    <>
      <div
        className="styleImageBackgroundFull relative "
        style={{ background: "url(" + bcImage + ")" }}
      >
        <div className="filtreImage"></div>
        <div className="desktopContent">
          <div className={color + "width80 auto padding30 relative"}>
            <div className="flex alignItemCenter justifyContentBetween">
              <Link to="/">
                <div className="flex alignItemCenter hover gap20">
                  <div>
                    <img className="logo" src={logo} alt="" />
                  </div>
                  <h1 className="h1">{DataContent.nomEntreprise}</h1>
                </div>
              </Link>
              <ul className="flex h4 alignItemCenter overFlowScroolTablette">
                {DataNavigation.map((item, key) => (
                  <li key={key}>
                    {item.sousMenu ? (
                      <div className="relative flex column">
                        <div className="overLiMenu overDeclencheMenu padding20">
                          {item.name}
                          <div className="hoverSousMenu flex column">
                            {item.sousMenu.map((item, key) => (
                              <Link to={item.destination}>
                                <div className="padding20 bcMenuOver">
                                  {item.name}
                                </div>
                              </Link>
                            ))}
                          </div>
                        </div>
                      </div>
                    ) : (
                      <Link to={item.destination}>
                        <div className="overLiMenu borderRadius5 padding20">
                          {item.name}
                        </div>
                      </Link>
                    )}
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div
            className={
              color +
              " width80 flex auto column justifyContentCenter height70vh relative"
            }
          >
            <div className="width60">
              <div className="marginBottomXl bigH1 bold">{desc}</div>
              <div className="marginBottomXxxxxl h2">{title}</div>
              <div onClick={() => window.open("./cv-meynard.pdf", "_blank")}>
                <Btn name="CV format PDF" />
              </div>
            </div>
          </div>
          <div className="bc_gradient padding10 listLangageSousMenu">
            <div className="flex justifyContentAround">
              <div className="flex alignItemCenter gap20">
                <div>
                  <img width="85px" src={ReactLogo} alt="Adrien Meynard" />
                </div>
                <div className="flex column justifyContentCenter alignItemCenter gap5">
                  <div className="h3 white">React / Typescript</div>
                  <div className="h4 orangered flex gap10">
                    <i className="fas fa-star yellow"></i>
                    <i className="fas fa-star yellow"></i>
                    <i className="fas fa-star yellow"></i>
                    <i className="fas fa-star yellow"></i>
                  </div>
                </div>
              </div>

              <div className="flex alignItemCenter gap20">
                <div>
                  <img width="85px" src={NodeLogo} alt="Adrien Meynard" />
                </div>
                <div className="flex column justifyContentCenter alignItemCenter gap5">
                  <div className="h3 white">Node.js</div>
                  <div className="h4 orangered flex gap10">
                    <i className="fas fa-star yellow"></i>
                    <i className="fas fa-star yellow"></i>
                    <i className="fas fa-star yellow"></i>
                  </div>
                </div>
              </div>

              <div className="flex alignItemCenter gap20">
                <div>
                  <img width="85px" src={Logo3} alt="Adrien Meynard" />
                </div>
                <div className="flex column justifyContentCenter alignItemCenter gap5">
                  <div className="h3 white">MongoDB / SQL</div>
                  <div className="h4 orangered flex gap10">
                    <i className="fas fa-star yellow"></i>
                    <i className="fas fa-star yellow"></i>
                    <i className="fas fa-star yellow"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

MenuImageGrande.propTypes = {
  title: PropTypes.string,
  btnName: PropTypes.string,
  desc: PropTypes.string,
  logo: PropTypes.any,
  color: PropTypes.string,
  bcImage: PropTypes.any,
  btnDestination: PropTypes.string,
};

MenuImageGrande.defaultProps = {
  title: DataContent.title,
  desc: DataContent.desc,
  logo: DataContent.logo,
  color: DataContent.color,
  bcImage: DataContent.bcImage,
  btnName: DataContent.btnName,
  btnDestination: DataContent.btnDestination,
};

export default MenuImageGrande;
