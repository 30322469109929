import React from "react";

const hobbies = [
  { label: "Informatique", icon: "fas fa-laptop-code" },
  { label: "Enseigner", icon: "fas fa-chalkboard-teacher" },
  { label: "Sport", icon: "fas fa-football-ball" },
  { label: "Égypte Antique", icon: "fab fa-avianex" },
  { label: "Astronomie", icon: "fas fa-meteor" },
  { label: "Lecture", icon: "fas fa-book-reader" },
];

const DataHobbies = () => {
  return (
    <div className="flex justifyContentAround black columnM gap40M">
      {hobbies.map((item) => {
        return (
          <div className="flex column alignItemCenter gap10">
            <div className="bc-gradientOrange borderRadius50 flex alignItemCenter justifyContentCenter">
              <i className={`${item.icon} white iconHobbies`}></i>
            </div>
            <div className="h4">{item.label}</div>
          </div>
        );
      })}
    </div>
  );
};

export default DataHobbies;
