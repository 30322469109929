import Code from "../img/code.svg";
import Employe from "../img/employe.svg";

const data = [
  {
    img: Code,
    title: "Developpeur Front-end (ReactJs) - Intrasense",
    desc: "septembre 2023 - actuellement",
  },
  {
    img: Code,
    title: "Developpeur Full Stack (ReactJs / Vue.js / Node.js) - Smile",
    desc: "septembre 2021 - août 2023",
  },
  {
    img: Code,
    title: "Développeur Front-end (ReactJs) - MyMoneyBank",
    desc: "septembre 2020 - août 2021",
  },
];

export default data;
