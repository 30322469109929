import React, { useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Remonter from "./components/remonter";
import Home from "./pages/home";
import Footer from "./components/footer";
import { useLocation } from "react-router-dom";
import Page404 from "./components/page404";
import DessinMp4 from "./img/dessin.mp4";
import "./app.css";

const ScrollToTop = () => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return "";
};

const App = () => {
  return (
    <>
      <Router>
        {/* <ScrollToTop /> */}
        <div>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route
              path="/dessin"
              component={() => (
                <video className="videoDessin" controls>
                  <source
                    src={DessinMp4}
                    type="video/mp4"
                    allowfullscreen
                  ></source>
                </video>
              )}
            />
            <Route path="/" component={Page404} />
          </Switch>
        </div>
        <Footer />
        <Remonter />
      </Router>
    </>
  );
};

export default App;
