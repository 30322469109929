import React, { useState } from "react";
import Loading from "../loading";
import { ToastContainer, toast } from "react-toastify";
import imgContact from "./img/contact.png";
import axios from "axios";
import Btn from "../btnContact";
import "react-toastify/dist/ReactToastify.css";
import "./index.css?vers=1.0.190";

const Contact = () => {
  const [name, setName] = useState("");
  const [nameErr, setNameErr] = useState("");

  const [email, setEmail] = useState("");
  const [emailErr, setEmailErr] = useState("");

  const [loading, setLoading] = useState("none");

  const regex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-])/;

  const urlApiSendMail = "https://www.adrien-meynard.fr/send/index.php";

  const Submit = (e) => {
    e.preventDefault();
    setLoading("true");
    setNameErr("");
    setEmailErr("");

    let err = 0;
    if (name === "") {
      setNameErr("Champ vide");
      err = 1;
    }

    if (email === "") {
      setEmailErr("Champ vide");
      err = 1;
    } else if (!email.match(regex)) {
      setEmailErr("Email non valide");
      err = 1;
    }

    if (err === 1) {
      setLoading("none");
      toast.error("Erreur ..");
    }

    if (err === 0) {
      axios({
        method: "POST",
        url: urlApiSendMail,
        data: {
          nom_entreprise: "Adrien Meynard.fr",
          contact: 1,
          email: email,
          message: name,
          token: "rtugifdnk6",
        },
      })
        .then((res) => {
          setNameErr("");
          setEmailErr("");
          setName("");
          setEmail("");
          toast.success("Email envoyé !");
          setLoading("none");
        })
        .catch((err) => {
          toast.error("Erreur ! Veuillez réessayer dans quelques minutes");
          setLoading("none");
        });
    }
  };
  return (
    <>
      <Loading status={loading} />
      <div className="marginDiv">
        <div className="marginCoteContact flex alignItemCenter">
          <div className="contactGauche">
            <div className="marginBottomXxxl">
              <p className="h3 litlePaddingCOntact">Email</p>
              <input
                type="text"
                placeholder="votre email ..."
                onChange={(e) => setEmail(e.target.value)}
                value={email}
              />
              <p className="err h5">{emailErr}</p>
            </div>
            <div className="marginBottomXxxl">
              <p className="h3 litlePaddingCOntact">Message</p>
              <textarea
                placeholder="votre message ..."
                onChange={(e) => setName(e.target.value)}
                value={name}
              />
              <p className="err h5">{nameErr}</p>
            </div>
            <div onClick={(e) => Submit(e)}>
              <Btn name="Soumettre le formulaire" />
            </div>
          </div>
          <div className="contactDroite">
            <img src={imgContact} />
          </div>
        </div>
      </div>
      <br />
      <ToastContainer />
    </>
  );
};

export default Contact;
