import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import DataContent from "../../dataContent";
import DataNavigation from "../../dataNavigation";
import Btn from "../../../btn";
import Menu from "../menu";
import { BrowserRouter as Router, Link } from "react-router-dom";
import "./index.css?vers=1.0.19";
import "../menuMobile.css?vers=1.0.19";

const MenuClassiqueMobile = ({
  color,
  bc,
  orientation,
  btnName,
  btnDestination,
  bcColorMenuOuvert,
  fixed,
  mailMenuOuvert,
  telMenuOuvert,
}) => {
  const [statusMenu, setStatusMenu] = useState("");

  bc = "bc-" + bc;
  bcColorMenuOuvert = "bc-" + bcColorMenuOuvert;
  if (fixed) {
    fixed = " fixedMenu";
  }
  return (
    <>
      <div
        className={
          color +
          " flex justifyContentBetween paddingMenu alignItemCenter " +
          bc +
          fixed
        }
      >
        <div>
          <img className="imageLogo" src={DataContent.logo} />
        </div>
        <div>
          <h1 className="h3">{DataContent.nomEntreprise}</h1>
        </div>
        <div
          onClick={() => setStatusMenu("openMenu" + orientation)}
          className="flex justifyContentRight alignItemCenter imageLogo"
        >
          <i className="fas fa-bars barMenu" aria-hidden="true"></i>
        </div>
        {statusMenu && (
          <>
            <div
              className={
                "Menu" +
                orientation +
                " absoluteMenu padding20 " +
                bcColorMenuOuvert +
                " " +
                statusMenu
              }
            >
              <div
                className="croixCloseMenu absolute flex alignItemCenter"
                onClick={() => setStatusMenu("closeMenu" + orientation)}
              >
                <i className="fa fa-window-close" aria-hidden="true"></i>
              </div>
              <div className="marginBottomXxxxl"></div>
              <div className="flex alignItemCenter marginBottomXxxxxl">
                <div>
                  <img className="imageLogo" src={DataContent.logoMobile} />
                </div>
                <p className="h1">&nbsp;{DataContent.nomEntreprise}</p>
              </div>
              <ul className="flex h3 column marginBottomXxxxxl">
                {DataNavigation.map((item, key) => (
                  <li key={key}>
                    {item.sousMenu ? (
                      <Menu item={item} color={color} />
                    ) : (
                      <>
                        <Link to={item.destination}>
                          <p>{item.name}</p>
                        </Link>
                        <div className={"separtor bc-" + color}></div>
                      </>
                    )}
                  </li>
                ))}
                {btnName && <Btn name={btnName} destination={btnDestination} />}
              </ul>
              <div className="h4 white flex column gap20">
                <div className="flex">
                  <i className="material-icons selfCenter">mail</i>
                  &nbsp;
                  <a href="mailto:contact@digitac.fr">{mailMenuOuvert}</a>
                </div>
                <div className="flex">
                  <i className="material-icons selfCenter">phone</i>
                  &nbsp;
                  <a href="tel:+33695632515">{telMenuOuvert}</a>
                </div>
              </div>
            </div>
          </>
        )}
        {statusMenu === "openMenu" + orientation && (
          <div
            className="divCloseMenu"
            onClick={() => setStatusMenu("closeMenu" + orientation)}
          ></div>
        )}
      </div>
    </>
  );
};
MenuClassiqueMobile.propTypes = {
  title: PropTypes.string,
  btnName: PropTypes.string,
  logo: PropTypes.any,
  color: PropTypes.string,
  btnDestination: PropTypes.string,
  bc: PropTypes.string,
  bcColorMenuOuvert: PropTypes.string,
  orientation: PropTypes.string,
  fixed: PropTypes.bool,
  mailMenuOuvert: PropTypes.string,
  telMenuOuvert: PropTypes.string,
};

MenuClassiqueMobile.defaultProps = {
  title: DataContent.title,
  logo: DataContent.logo,
  color: DataContent.color,
  btnName: DataContent.btnName,
  btnDestination: DataContent.btnDestination,
  bc: DataContent.bc,
  bcColorMenuOuvert: DataContent.bcColorMenuOuvert,
  orientation: "Left",
  fixed: DataContent.fixed,
  mailMenuOuvert: DataContent.mailMenuOuvert,
  telMenuOuvert: DataContent.telMenuOuvert,
};

export default MenuClassiqueMobile;
