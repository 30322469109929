import React from "react";
import PropTypes from "prop-types";
import "./index.css?vers=1.0.19";

const Title = ({ title, desc, colorTitle, colorDesc, soulignerTitle }) => {
  return (
    <>
      <div className={" h1 center textGradientOrange"}>{title}</div>
      {soulignerTitle ? (
        <div className="soulignerTitre soulignerTexte"></div>
      ) : (
        <div className="marginBottomXxl"></div>
      )}
      {
        <div className={colorDesc + " h4 center marginBottomXl flex"}>
          {desc}
        </div>
      }
    </>
  );
};

Title.propTypes = {
  title: PropTypes.string,
  desc: PropTypes.string,
  colorTitle: PropTypes.string,
  colorDesc: PropTypes.string,
  soulignerTitle: PropTypes.bool,
};

Title.defaultProps = {
  title: "",
  desc: "",
  colorTitle: "linear-gradient(90.6deg, #FF9A02 0.52%, #FF2E00 83.34%) ",
  colorDesc: "white ",
  soulignerTitle: true,
};

export default Title;
