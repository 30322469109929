const DataNavigation = [
  {
    name: "Projets",
    destination: "./#projets",
  },
  {
    name: "Formations",
    destination: "./#formations",
  },
  {
    name: "Experiences",
    destination: "/#experiences",
  },
  {
    name: "Stages",
    destination: "./#stages",
  },
  {
    name: "Hobbies",
    destination: "./#hobbies",
  },
  {
    name: "Compétences",
    destination: "./#competences",
  },
  {
    name: "Contact",
    destination: "./#contact",
  },
];

export default DataNavigation;
