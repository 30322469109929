import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import "./index.css?vers=1.0.19";

const Btn = ({ name, destination, center }) => {
  if (center) {
    center = "auto justifyContentCenter ";
  }

  return (
    <>
      <div className={center + " flex height100"}>
        {destination && (
          <Link to={destination}>
            <div className="h4 btn centerM borderBox">{name}</div>
          </Link>
        )}

        {!destination && (
          <div className="white h4 btn centerM borderBox">{name}</div>
        )}
      </div>
    </>
  );
};

Btn.propTypes = {
  name: PropTypes.string,
  destination: PropTypes.string,
  center: PropTypes.bool,
};

Btn.defaultProps = {
  name: "",
  destination: "",
  center: false,
};

export default Btn;
