import React from "react";
// import Menu from "../assets/menu";
import BearGif from "./img/bear.gif";
import Btn from "../btnContact";
import "./index.css?vers=1.0.19";
import MenuDesktop from "../header/desktop/menuImagePetite";
import MenuMobile from "../header/mobile/menuImage";

const Page404 = () => {
  document.title = "Adrien Meynard | Page 404";
  return (
    <>
      <div className="desktop">
        <MenuDesktop title="Erreur - Page 404" desc="" texte="" />
      </div>
      <div className="mobile">
        <MenuMobile title="Erreur - Page 404" desc="" texte="" />
      </div>
      <div className="bc-beige">
        <section>
          <div className="center marginBottomXxl">
            <Btn
              name="Retour à la page d'accueil"
              destination="/"
              center={true}
            />
          </div>
          <div className="center">
            <img src={BearGif} alt="" />
          </div>
        </section>
      </div>
    </>
  );
};

export default Page404;
