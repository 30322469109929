import React from "react";
import DataNavigation from "../../dataNavigation";
import DataContent from "../../dataContent";
import PropTypes from "prop-types";
import Btn from "../../../btn";
import { BrowserRouter as Router, Link } from "react-router-dom";
import "./index.css?vers=1.0.19";

const MenuClassique = ({ bc, color, logo, btnDestination, btnName }) => {
  bc = "bc-" + bc;
  return (
    <>
      <div className={bc}>
        <div className={color + "width80 auto padding30"}>
          <div className="flex alignItemCenter justifyContentBetween">
            <Link to="/">
              <div className="flex alignItemCenter hover gap20">
                <div>
                  <img className="logo" src={logo} alt="" />
                </div>
                <h1 className="h1">{DataContent.nomEntreprise}</h1>
              </div>
            </Link>
            <ul className="flex h4 alignItemCenter">
              {DataNavigation.map((item, key) => (
                <li key={key}>
                  {item.sousMenu ? (
                    <div className="relative flex column">
                      <div className="overLiMenu overDeclencheMenu padding20">
                        {item.name}
                        <div className="hoverSousMenu flex column">
                          {item.sousMenu.map((item, key) => (
                            <Link key={key} to={item.destination}>
                              <div className="padding20 bcMenuOver">
                                {item.name}
                              </div>
                            </Link>
                          ))}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <Link to={item.destination}>
                      <div className="overLiMenu borderRadius5 padding20">
                        {item.name}
                      </div>
                    </Link>
                  )}
                </li>
              ))}
              &nbsp;&nbsp;
              {btnName && <Btn name={btnName} destination={btnDestination} />}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

MenuClassique.propTypes = {
  logo: PropTypes.any,
  bc: PropTypes.string,
  color: PropTypes.string,
  btnDestination: PropTypes.string,
  btnName: PropTypes.string,
};

MenuClassique.defaultProps = {
  logo: DataContent.logo,
  bc: DataContent.bc,
  color: DataContent.color,
  btnDestination: DataContent.btnDestination,
  btnName: DataContent.btnName,
};

export default MenuClassique;
