import React from "react";
import ImgQcmPro from "../img/qcm-pro.png";
import ImgDebattons from "../img/debattons.png";
import ImgJeuxMobile from "../img/jeux-mobile.png";
import ImgFestival from "../img/festival.png";
import ImgPaysPrenom from "../img/pays-prenom.png";
import ImgVirusland from "../img/virusland.png";
import ImgFirebase from "../img/auth-firebase.png";
import ImgDessin from "../img/dessin.png";
import ImgAspirateur from "../img/aspirateur.png";
import ImgCard from "../img/card.png";
import ImgJeuPendu from "../img/jeu_pendu.png";
import ImgJeuMorpion from "../img/jeu_morpion.png";
import NewQcmPro from "../img/newQcmPro.png";
import Digitac from "../img/digitac.png";
import ImgJeuPuissance4 from "../img/puissance4.png";
import Immobilier from "../img/immobilier.jpg";
import Shifumi from "../img/shifumi.png";
import Gally from "../img/gally.png";

const data = [
  {
    img: NewQcmPro,
    title: "",
    desc: "",
    text: "",
    hoverTitle: "React / Next / Redux / Storybook / Jest / NodeJs / MongoDb",
    hoverDesc:
      "Logiciel de QCM en ligne pour professeur. Entièrement personalisable",
    hoverText: "",
    destination: "https://www.qcm-pro.fr/help",
  },
  {
    img: ImgDessin,
    title: "",
    desc: "",
    text: "",
    hoverTitle: "React Native / Node Js",
    hoverDesc:
      "Réalité augmentée : l'utilisateur colorie un modèle puis le scan. Il peut alors voir son dessin en 3D avec les mêmes couleurs sous toutes ces formes",
    hoverText: "Application mobile ios/android",
    destination: "./dessin",
  },
  {
    img: Gally,
    title: "",
    desc: "",
    text: "",
    hoverTitle: "REACT / SYMFONY",
    hoverDesc:
      "Logiciel open source afin d'optimiser vos ventes pour les sites e-commerce",
    hoverText: "",
    destination: "https://github.com/Elastic-Suite",
  },
  {
    img: ImgCard,
    title: "",
    desc: "",
    text: "",
    hoverTitle: "REACT",
    hoverDesc: "Boostez votre mémoire avec des thèmes ludiques",
    hoverText: "",
    destination: "http://card.adrien-meynard.fr",
  },
  {
    img: ImgJeuPendu,
    title: "",
    desc: "",
    text: "",
    hoverTitle: "REACT",
    hoverDesc: "Jeu du pendu",
    hoverText: "",
    destination: "http://jeu-du-pendu.adrien-meynard.fr/",
  },
  {
    img: ImgJeuMorpion,
    title: "",
    desc: "",
    text: "",
    hoverTitle: "React",
    hoverDesc: "Jeu du morpion",
    hoverText: "",
    destination: "http://jeu-du-morpion.adrien-meynard.fr/",
  },
  {
    img: ImgJeuPuissance4,
    title: "",
    desc: "",
    text: "",
    hoverTitle: "React",
    hoverDesc: "Jeu du puissance 4",
    hoverText: "",
    destination: "https://jeu-du-puissance4.adrien-meynard.fr/",
  },
  {
    img: Shifumi,
    title: "",
    desc: "",
    text: "",
    hoverTitle: "React",
    hoverDesc: "Jeu du Shi-fu-mi (pierre, feuille, ciseaux)",
    hoverText: "",
    destination: "https://shifumi.adrien-meynard.fr/",
  },
  {
    img: Digitac,
    title: "",
    desc: "",
    text: "",
    hoverTitle: "React",
    hoverDesc: "Agence Digital",
    hoverText: "",
    destination: "https://digitac.adrien-meynard.fr/",
  },
  {
    img: Immobilier,
    title: "",
    desc: "",
    text: "",
    hoverTitle: "React",
    hoverDesc: "Simulation locative",
    hoverText: "",
    destination: "https://stats-immo.adrien-meynard.fr/",
  },
  {
    img: ImgQcmPro,
    title: "",
    desc: "",
    text: "",
    hoverTitle: "PHP / AJAX / SQL",
    hoverDesc:
      "Logiciel de QCM en ligne pour professeur. Entièrement personalisable",
    hoverText: "Ancienne version (2019-2022)",
    destination: "https://qcm-pro.adrien-meynard.fr",
  },
  // {
  //   img: ImgFestival,
  //   title: "",
  //   desc: "",
  //   text: "",
  //   hoverTitle: "REACT / PHP",
  //   hoverDesc:
  //     "Gérer un festival avec un back office complet (plannings, messages, réseaux sociaux ..)",
  //   hoverText: "Format responsive uniquement mobile",
  //   destination: "http://festival.adrien-meynard.fr/",
  // },
  {
    img: ImgDebattons,
    title: "",
    desc: "",
    text: "",
    hoverTitle: "PHP / AJAX / SQL",
    hoverDesc:
      "Média interractif avec un interface utilisateur afin de commenter / liker / publier un article. Back office administrateur afin de réguler les articles",
    hoverText: "",
    destination: "http://debattons.adrien-meynard.fr/liste_article/index.php",
  },
  // {
  //   img: ImgJeuxMobile,
  //   title: "",
  //   desc: "",
  //   text: "",
  //   hoverTitle: "KOTLIN",
  //   hoverDesc:
  //     "Jeux mobile ludique de calculs mentaux avec différents niveaux disponible",
  //   hoverText: "Uniquement en .apk (android)",
  //   destination: "http://jeux-mobile.adrien-meynard.fr/",
  // },
  // {
  //   img: ImgVirusland,
  //   title: "",
  //   desc: "",
  //   text: "",
  //   hoverTitle: "REACT",
  //   hoverDesc: "Jeux vidéo pour améliorer sa précision avec la souris",
  //   hoverText: "",
  //   destination: "http://virusland.adrien-meynard.fr/",
  // },
  // {
  //   img: ImgFirebase,
  //   title: "",
  //   desc: "",
  //   text: "",
  //   hoverTitle: "React",
  //   hoverDesc: "Connexion avec Gmail / Github ou email",
  //   hoverText: "",
  //   destination: "http://auth-firebase.adrien-meynard.fr/",
  // },
  // {
  //   img: ImgPaysPrenom,
  //   title: "",
  //   desc: "",
  //   text: "",
  //   hoverTitle: "REACT",
  //   hoverDesc:
  //     "Apprendre les pays + connaître l'age moyen des personnes qui portent un prénom",
  //   hoverText: "Disponible en .exe",
  //   destination: "http://pays-prenom.adrien-meynard.fr/",
  // },
  // {
  //   img: ImgAspirateur,
  //   title: "",
  //   desc: "",
  //   text: "",
  //   hoverTitle: "React",
  //   hoverDesc:
  //     "Donner les dimensions du parcours + le chemin à suivre + l'orientation à un aspirateur pour obtenir sa position finale ainsi que son orientation (N,E,W,S)",
  //   hoverText: "",
  //   destination: "http://aspirateur.adrien-meynard.fr/",
  // },
];

export default data;
