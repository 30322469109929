// PAGE LORSQU'IL Y A UN SOUS MENU ------------------------------------------------------------------

import React, { useState } from "react";
import { BrowserRouter as Router, Link } from "react-router-dom";

const Menu = ({ item, color }) => {
  const [statusSousMenu, setStatusSousMenu] = useState("none");
  const [cvOpen, setCvOpen] = useState("");

  const StatusSousMenu = () => {
    if (cvOpen === "") {
      setCvOpen("cvOpen");
      setStatusSousMenu("open");
    } else {
      setCvOpen("");
      setStatusSousMenu("none");
    }
  };
  return (
    <div className="relative flex column">
      <div>
        <div
          className="flex justifyContentBetween"
          onClick={() => StatusSousMenu()}
        >
          <p>{item.name}</p>
          <div>
            <i
              className={
                "material-icons chevronSousMenu " + cvOpen + " " + color
              }
            >
              play_arrow
            </i>
          </div>
        </div>
        <div className={"flex column noPadding " + statusSousMenu}>
          {item.sousMenu.map((item, key) => (
            <Link key={key} to={item.destination}>
              <div className="flex alignItemCenter">
                <span className="bouleNoire"></span>
                <p className="padding10">{item.name}</p>
              </div>
            </Link>
          ))}
        </div>
      </div>
      <div className={"separtor bc-" + color}></div>
    </div>
  );
};

export default Menu;
