import React from "react";

const DataFormation = () => {
  return (
    <div className="width50 flex column gap20 width100M">
      <div className="flex gap20 alignItemCenter">
        <div>
          <div className="bc_etoile_formation borderRadius50  flex alignItemCenter justifyContentCenter">
            <i className="fas fa-star yellow"></i>
          </div>
        </div>
        <div className="flex alignItemCenter">
          <div className="flex column">
            <div className="black h3">Master EPSI, Montpellier</div>
            <div className="orangered h4">septembre 2021 - août 2023</div>
          </div>
        </div>
      </div>
      <div className="flex gap20 alignItemCenter">
        <div>
          <div className="bc_etoile_formation borderRadius50  flex alignItemCenter justifyContentCenter">
            <i className="fas fa-star yellow"></i>
          </div>
        </div>
        <div className="flex alignItemCenter">
          <div className="flex column">
            <div className="black h3">Licence EPSI, Montpellier</div>
            <div className="orangered h4">septembre 2018 - août 2021</div>
          </div>
        </div>
      </div>
      <div className="flex gap20 alignItemCenter">
        <div>
          <div className="bc_etoile_formation borderRadius50  flex alignItemCenter justifyContentCenter">
            <i className="fas fa-star yellow"></i>
          </div>
        </div>
        <div className="flex alignItemCenter">
          <div className="flex column">
            <div className="black h3">
              Titre RNCP Niveau 6, Concepteur Developpeur d'Application
            </div>
            <div className="orangered h4">juillet 2021</div>
          </div>
        </div>
      </div>
      <div className="flex gap20 alignItemCenter">
        <div>
          <div className="bc_etoile_formation borderRadius50  flex alignItemCenter justifyContentCenter">
            <i className="fas fa-star yellow"></i>
          </div>
        </div>

        <div className="flex alignItemCenter">
          <div className="flex column">
            <div className="black h3">
              Titre RNCP Niveau 5, Développeur Web et Web Mobile
            </div>
            <div className="orangered h4">mars 2021</div>
          </div>
        </div>
      </div>
      <div className="flex gap20 alignItemCenter">
        <div>
          <div className="bc_etoile_formation borderRadius50  flex alignItemCenter justifyContentCenter">
            <i className="fas fa-star yellow"></i>
          </div>
        </div>
        <div className="flex alignItemCenter">
          <div className="flex column">
            <div className="black h3">Certification Google Ads</div>
            <div className="orangered h4">avril 2020</div>
          </div>
        </div>
      </div>
      <div className="flex gap20 alignItemCenter">
        <div>
          <div className="bc_etoile_formation borderRadius50  flex alignItemCenter justifyContentCenter">
            <i className="fas fa-star yellow"></i>
          </div>
        </div>
        <div className="flex alignItemCenter">
          <div className="flex column">
            <div className="black h3">
              Baccalauréat technologique STI2D - mention bien, Lycée
              Joliot-Curie , Sète
            </div>
            <div className="orangered h4">juillet 2017</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DataFormation;
