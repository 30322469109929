import React from "react";
import PropTypes from "prop-types";
import "./index.css?vers=1.0.19";

const Grid = ({
  grid,
  data,
  bc,
  color,
  hover,
  imgCenter,
  imgRadius,
  radius,
}) => {
  bc = "bc-" + bc;
  if (data.length > 0) {
    return (
      <>
        <div className={grid}>
          {data.map((item, key) => {
            return (
              <div
                className={radius + bc + color + hover + " padding40"}
                key={key}
              >
                {item.img && (
                  <div className={imgCenter ? "center" : undefined}>
                    <img
                      className={
                        (imgRadius ? "borderRadius50" : undefined) +
                        " imagePicto"
                      }
                      src={item.img}
                      alt=""
                    />
                  </div>
                )}
                <div
                  className={
                    (item.img ? undefined : "noPaddingTop") + " h3 padding20_0 "
                  }
                >
                  {item.title}
                </div>
                <div className={(item.text && "marginBottomL") + " h4"}>
                  {item.desc}
                </div>
                {item.text && <div className="h5">{item.text}</div>}
              </div>
            );
          })}
        </div>
      </>
    );
  } else {
    return null;
  }
};

Grid.propTypes = {
  grid: PropTypes.string,
  data: PropTypes.array,
  bc: PropTypes.string,
  color: PropTypes.string,
  hover: PropTypes.string,
  imgCenter: PropTypes.bool,
  imgRadius: PropTypes.bool,
  radius: PropTypes.string,
};

Grid.defaultProps = {
  grid: "grid3_2_1 ",
  data: [],
  bc: "white ",
  color: "Black ",
  hover: " ",
  imgCenter: false,
  imgRadius: false,
  radius: "",
};

export default Grid;
