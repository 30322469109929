import React, { useState } from "react";

const DataImgBackground = ({ item }) => {
  const [visible, setVisible] = useState("none");

  let content = true;
  return (
    <div
      className={(item.destination && "pointer") + " width400 relative"}
      onMouseOver={() => setVisible("afficherTexteOver")}
      onMouseLeave={() => setVisible("none")}
      onClick={() =>
        item.destination ? window.open(item.destination, "_blank") : ""
      }
    >
      {item.title === "" &&
        item.desc === "" &&
        item.text === "" &&
        (content = false)}
      <div
        className="imageBackground relative"
        style={{ backgroundImage: "url(" + item.img + ")" }}
      >
        <div
          className={
            content
              ? "filtreImage"
              : visible === "none"
              ? "none filtreImage"
              : "filtreImage"
          }
        ></div>
        <div className="width400">
          {content && (
            <div className="padding20 flex relative column alignItemCenter justifyContentCenter gap30 height100 white borderBox">
              {item.title && <div className="h2 center">{item.title}</div>}
              {item.desc && <div className="h4 center">{item.desc}</div>}
              {item.text && <div className="h5 center">{item.text}</div>}
            </div>
          )}
          {!content && (
            <div
              className={
                visible +
                " padding20 flex relative column alignItemCenter justifyContentCenter gap30 height100 white borderBox"
              }
            >
              {item.hoverTitle && (
                <div className="h2 center">{item.hoverTitle}</div>
              )}
              {item.hoverDesc && (
                <div className="h4 center">{item.hoverDesc}</div>
              )}
              {item.hoverText && (
                <div className="h5 center">{item.hoverText}</div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DataImgBackground;
